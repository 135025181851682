import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Redirect } from '../components/Redirect';
import { Error } from '../components/statusTypes/Error';
import { Success } from '../components/statusTypes/Success';
import { Download } from '../components/statusTypes/Download';
import { CheckoutHost } from '../components/checkout/CheckoutHost';
import { SuccessPayment } from '../components/statusTypes/SuccessPayment';
// import { BasicRoute } from './BasicRoute';
// import { GoldRoute } from './GoldRoute';

export const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path='/' element={<Redirect />} />
                <Route path='/gold' element={<GoldRoute />} >
                    <Route path='success' element={<Success type='subscription' />} />
                    <Route path='error' element={<Error type='500' />} />
                </Route>
                <Route path='/basic' element={<BasicRoute />} >
                    <Route path='success' element={<Success type='subscription' />} />
                    <Route path='error' element={<Error type='500' />} />
                </Route> */}
                {/*-----------------------------------------------*/}
                <Route path='/success' element={<Success />} />
                <Route path='/success-payment' element={<SuccessPayment />} />
                <Route path='/download' element={<Download />} />
                <Route path='/error' element={<Error type='400' />} />
                <Route path='/checkout' element={<CheckoutHost />}/>
                <Route path='*'
                    element={<Redirect />}>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
