import { useEffect } from 'react'
import { Loader } from './Loader'

export const Redirect = () => {

    useEffect(() => {
        window.location.href = 'https://ellie.care'
    }, [])

    return (
        <div className='mar-t-100'>
            <Loader size={72} title='Redireccionando...' />
        </div>
    )
}
