import '../../styles/components/_success.scss'
import backRed from '../../images/background/background-red.png'
import backRedMobile from '../../images/background/background-red-mobile.png'
import error400 from '../../images/background/error-401-403.svg'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { Col, Container, Row } from 'react-bootstrap'
import { Button } from '../Button'

interface ErrorProps {
    type: '400' 
}

export const Error = ({ type }: ErrorProps) => {
    const mobileQuery = useMediaQuery('(max-width: 768px)')

    const handleClickNavigate = () => {
        window.location.href = 'https://ellie.care/NuestrosPlanes';
    }

    return (
        <div className='success-container bg-ui-background'>
            <div className='img-container'>
                {
                    mobileQuery
                        ? <img src={backRedMobile} alt='' className='w-100 img-green' />
                        : <img src={backRed} alt='' className='w-100 img-green' />
                }

            </div>
            <Container className='z-index-100 card-position'>
                <Row className='d-flex center-box flex-direction-column p-3'>
                    {
                       
                            <>
                                <Col md={2} className='d-flex justify-content-center'>
                                    <img src={error400} alt='' />
                                </Col>
                                <Col md={6} className='bg-ui-01 card-success p-4' >
                                    <h1>Tu suscripción no se pudo efectuar</h1>
                                    <p className='subtitle-m mar-t-10 mar-b-32'>
                                        Tuvimos un inconveniente técnico y no pudimos procesar tu pago. Por favor vuelve a intentarlo.
                                    </p>
                                    <Button
                                        text='Intentar nuevamente'
                                        size='l'
                                        className={mobileQuery ? 'w-100' : 'w-60'}
                                        onClick={handleClickNavigate}
                                    />
                                </Col>
                            </>
                    }
                </Row>
            </Container>
        </div>
    )
}