import "../../styles/components/_success.scss";
import { Col, Row, Button, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { existOnePlan } from "../../services/plans.service";
import { Loader } from "../Loader";

export const SuccessPayment = () => {
  const mepa_logo_url =
    "https://authenticator.2stable.com/assets/img/2fa-services/Icons/mercadopago.com.svg";
  const discountCodes = ["BANDAGOLD", "BIENESTAR2023"];
  const mepaLogo = (
    <img
      src={mepa_logo_url}
      alt="Logo MercadoPago"
      className="logo-m"
      style={{ height: "1.5rem" }}
    />
  );
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id") || ""; //id suscripcion
  const [infoPlan, setInfoPlan] = useState({
    isLoan: false,
    plan_type: "gold",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (!id) {
      // No se encontraron id en la URL
      navigate(`/error`);
    } else {
      existOnePlan(id)
        .then((res) => {
          setInfoPlan({ isLoan: res.isLoan, plan_type: res.plan_type });
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);

          navigate(`/error`);

        });
    }
  }, [id, navigate]);

  //-- Cupón de descuento ----------------------------//
  const [inputText, setInputText] = useState("");
  const [isMatched, setIsMatched] = useState(false);
  const [isntMatched, setIsntMatched] = useState(false);

  const handleCompare = () => {
    if (discountCodes.includes(inputText)) {
      setIsMatched(true);
      setIsntMatched(false);
    } else {
      setIsMatched(false);
      setIsntMatched(true);
    }
  };

  //-- Función para mostrar el contenido común -------//
  function renderCommonContent() {
    return (
      <>
        <p className="mar-t-10 mar-b-32">
          Te vamos a enviar a tu WhatsApp y a tu email un mensaje con:
        </p>
        <ol>
          <li className="mar-t-10 mar-b-10">
            Un <b>link</b> para que instales nuestra aplicación en tu celular.
          </li>
          <li className="mar-t-10 mar-b-10">
            Las instrucciones para que:
            <ul>
              <li className="mar-t-10 mar-b-10">
                <b>crees una cuenta</b> si vas a cuidar a un Adulto Mayor
              </li>
              <li className="mar-t-10 mar-b-10">
                <b>inicies sesión</b> y completes tus datos si sos quién va a
                ser cuidado.
              </li>
            </ul>
          </li>
          <li className="mar-t-10 mar-b-10">
            El <b>código de acceso</b> y las instrucciones para ingresar en la
            Red de Ayuda <b>como Administrador</b> (cuidador) con ese código.
          </li>
        </ol>

        {/* <div
          className="d-flex justify-content-center"
          style={{ height: "500px", width: "auto" }}
        >
          <img src={joinNetworkGif} alt="Un GIF animado genial" />
        </div> */}

        <p className="mar-t-10 mar-b-10">
          {/* Si no lo recibiste, por favor hace click en Reenviar código o comunícate con nosotros al siguiente número de teléfono: */}
          Si no lo recibiste, por favor comunícate con nosotros al siguiente
          número de teléfono:
        </p>
        <p className="mar-t-10 d-flex justify-content-center">
          <b>0800 888 3554 (Ellie)</b>
        </p>
      </>
    );
  }

  //-- Función para mostrar el Footer -------//
  function renderFooter() {
    return (
      <table style={{ width: "100%" }}>
        <tbody>
          <tr
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <th scope="col">
              <img
                src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/LogoLightMode.svg"
                height="100"
                alt=""
                style={{ verticalAlign: "middle" }}
              />
            </th>

            <th scope="col" style={{ paddingLeft: "20px" }}>
              <table width="450">
                <tbody>
                  <tr>
                    <th scope="col">
                      <p
                        style={{
                          color: "#0066ff",
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "0px",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        CONEXIÓN CON TU BIENESTAR
                      </p>
                      <hr
                        style={{
                          border: "none",
                          height: "2px",
                          backgroundColor: "#0066ff",
                          margin: "0 auto",
                          marginTop: "10px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      />
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <table width="450">
                        <tbody>
                          <tr>
                            <th id="fino" scope="col" align="left">
                              <img
                                src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/phoneLightMode.svg"
                                height="20"
                                alt=""
                                style={{ verticalAlign: "middle" }}
                              />
                              <span style={{ verticalAlign: "middle" }}>
                                0800 888 3554
                              </span>
                            </th>
                            <th id="fino" scope="col" align="left">
                              <a
                                href="https://ellie.care"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "none",
                                  color: "#3c3c3b",
                                }}
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/worldLightMode.svg"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                                <span style={{ verticalAlign: "middle" }}>
                                  www.ellie.care
                                </span>
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                href="https://www.linkedin.com/company/ellie-care/mycompany/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/linkedinLightMode.svg"
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                href="https://www.instagram.com/elliecare.latam/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/instagramLightMode.svg"
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                href="https://twitter.com/elliecareok"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/twitterLightMode.svg"
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                              </a>
                            </th>
                            <th scope="col">
                              <a
                                href="https://www.facebook.com/Ellie-Care-114087367948463"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/facebookLightMode.svg"
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                              </a>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </th>
          </tr>
        </tbody>
      </table>
    );
  }
  //--------------------------------------------------//

  if (loading) return <Loader title="Cargando..." size={120} />;

  return (
    <div className="d-flex justify-content-center">
      <table
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {infoPlan.isLoan ? (
          // PLAN GOLD COMODATO
          <Col
            md={10}
            className="bg-ui-01 card-success p-4"
            style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <tr style={{ display: "flex" }}>
                <td
                  style={{
                    width: "100%",
                    backgroundColor: "#0066ff",
                    color: "#f5f7f9",
                    textAlign: "center",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <h2>{"¡Tu Plan Gold Comodato ya casi está listo!"}</h2>
                </td>
              </tr>
              <p className="mar-t-10 mar-b-32">
                Nos vamos a poner en contacto con vos para realizar el envío del
                reloj cuándo lo tengamos preparado.
              </p>

              <h1 className="title mar-t-32">{"Además..."}</h1>

              {renderCommonContent()}
            </div>
            {renderFooter()}
          </Col>
          
        ) : (
          // PLAN GOLD CLÁSICO
          <Col
            md={10}
            className="bg-ui-01 card-success p-4"
            style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <tr style={{ display: "flex" }}>
                <td
                  style={{
                    width: "100%",
                    backgroundColor: "#0066ff",
                    color: "#f5f7f9",
                    textAlign: "center",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <h2>{"¡Tu Plan Gold ya casi está listo!"}</h2>
                </td>
              </tr>
              <h1 className="title mar-t-32">
                {"Solo falta comprar el reloj"}
              </h1>
              <p className="mar-t-10 mar-b-10">
                Para terminar, te invitamos a que sigas con la compra del
                Smartwatch Samsung de Ellie Care.
              </p>

              <p className="mar-t-10 mar-b-10">
                Si tenés un cupón de descuento, ingresalo en la casilla y
                validalo. Sino, continuá con la compra de tu reloj.
              </p>

              {!isMatched && (
                <div>
                  <div /*className="d-flex justify-content-center"*/>
                    <Form>
                      <Row>
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="Cupón de descuento"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <Button
                            onClick={handleCompare}
                            variant="success"
                            className="w-100"
                          >
                            Validar cupón
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>

                  <div /*className="d-flex justify-content-center btn btn-rpimary"*/
                  >
                    <Button
                      type="submit"
                      className="w-100 mar-t-20"
                      onClick={() =>
                        (window.location.href = "https://mpago.la/27FpWqD")
                      }
                    >
                      {mepaLogo} Continuar con la compra de tu reloj
                    </Button>
                  </div>
                </div>
              )}

              {isMatched && (
                <div /*className="d-flex justify-content-center btn btn-rpimary"*/
                >
                  <Button
                    type="submit"
                    className="w-100"
                    onClick={() =>
                      (window.location.href = "https://mpago.la/28yo6dk")
                    }
                  >
                    {mepaLogo} Continuar con la compra de tu reloj con descuento
                  </Button>
                </div>
              )}

              {isntMatched && (
                <div>
                  <p style={{ color: "#D73225" }}>
                    El código de descuento no es válido.
                  </p>
                </div>
              )}
              {isMatched && (
                <div>
                  <p style={{ color: "#427E46" }}>
                    El código de descuento es válido y se aplicó con éxito.
                  </p>
                </div>
              )}

              <p className="mar-t-10 mar-b-32">
                Nos vamos a poner en contacto con vos para realizar el envío del
                reloj cuándo lo tengamos preparado.
              </p>

              <h1 className="title mar-t-32">{"Además..."}</h1>

              {renderCommonContent()}
            </div>

            {renderFooter()}
          </Col>
        )}
      </table>
    </div>
  );
};
