import "../../styles/components/_success.scss";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputLabel,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { ChangeEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import es from "react-phone-input-2/lang/es.json";

import usePlacesAutocomplete from "use-places-autocomplete";

import { DateValidationError, LocalizationProvider, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createPreCheckoutMP } from "../../services/checkout.service";
import { Loader } from "../Loader";
import { FormData } from "../../utils/types";
import moment from "moment";

interface IProps {
  isLoan: boolean;
  plan_type: string;
}

const URLTermsAndCondition =
  "https://terminos.ellie.care/?_ga=2.77141202.633674769.1679946093-444473673.1671628896&_gl=1*18kiqly*_gcl_aw*R0NMLjE2NzYwNDAwNjEuQ2p3S0NBaUE4NWVmQmhCYkVpd0FEN29MUURjRk5DNTQtX2J5VXMxclQyeXBQLWRaN2lVell6WWpScGttOW5wSWN2X3hOcndFZ3lrTWtob0NHdzRRQXZEX0J3RQ..*_ga*NDQ0NDczNjczLjE2NzE2Mjg4OTY.*_ga_04WBQQV4M0*MTY3OTk0NjA5My4yMy4wLjE2Nzk5NDYxMDEuMC4wLjA";

const URLPoliciesPrivacy =
  "https://politicas.ellie.care/?_ga=2.10411890.633674769.1679946093-444473673.1671628896&_gl=1*1qab03g*_gcl_aw*R0NMLjE2NzYwNDAwNjEuQ2p3S0NBaUE4NWVmQmhCYkVpd0FEN29MUURjRk5DNTQtX2J5VXMxclQyeXBQLWRaN2lVell6WWpScGttOW5wSWN2X3hOcndFZ3lrTWtob0NHdzRRQXZEX0J3RQ..*_ga*NDQ0NDczNjczLjE2NzE2Mjg4OTY.*_ga_04WBQQV4M0*MTY3OTk0NjA5My4yMy4wLjE2Nzk5NDYxMDEuMC4wLjA";

export const Formulario = ({ isLoan, plan_type }: IProps) => {
  const mepa_logo_url =
    "https://authenticator.2stable.com/assets/img/2fa-services/Icons/mercadopago.com.svg";
  // const discountCodes = ["BANDAGOLD", "BIENESTAR2023"];
  const mepaLogo = (
    <img
      src={mepa_logo_url}
      alt="Logo MercadoPago"
      className="logo-m"
      style={{ height: "1.5rem" }}
    />
  );
  const location = useLocation();
  // const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid") || ""; //id suscripcion

  const {
    ready,
    value,
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const [isProcesingPayment, setIsProcesingPayment] = useState(false);
  const navigate = useNavigate();

  const [checkedTerms, setCheckedTerms] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [fnInvalid, setfnInvalid] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTerms(event.target.checked);
  };

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const sid = searchParams.get("sid"); //id suscriptor
  //   const status = searchParams.get("status");
  //   const uid = searchParams.get("uid") || ""; //id suscripcion

  //   if (!sid || !uid || status !== "200") {
  //     // No se encontraron sid o uid en la URL
  //     navigate(`/error`);
  //   }
  //   // Realizar búsqueda en la base de datos utilizando los valores obtenidos
  //   const subscriber = async () => {
  //     try {
  //       const res = await axios.get(
  //         `${process.env.REACT_APP_API_HOST}mobbex/subscriber`,
  //         {
  //           params: {
  //             uid,
  //             sid,
  //           },
  //         }
  //       );
  //       const subscriberData: Webhook = res.data;
  //       subscriberData.data.subscriber.calendar = [];
  //       subscriberData.data.subscriber.agenda = [];
  //       subscriberData.data.subscriber.activeSources = [];
  //       subscriberData.data.subscriber.customer.country_code = "+54";
  //       subscriberData.data.payment = { status: { code: "200" } };
  //       subscriberData.data.context = { status: "success" };
  //       subscriberData.type = "subscription:registration";
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   subscriber();
  // }, [location, navigate]);

  //-- Cupón de descuento ----------------------------//
  // const [discountText, setdiscountText] = useState("");
  // const [isMatched, setIsMatched] = useState(false);
  // const [isntMatched, setIsntMatched] = useState(false);

  // const handleCompare = () => {
  //   if (discountCodes.includes(discountText)) {
  //     setIsMatched(true);
  //     setIsntMatched(false);
  //   } else {
  //     setIsMatched(false);
  //     setIsntMatched(true);
  //   }
  // };

  //-- Función para mostrar el Footer -------//
  function renderFooter() {
    return (
      <table
        style={{
          width: "100%",
          paddingTop: "50px",
          backgroundColor: "#FCFCFC",
        }}
      >
        <tbody>
          <tr
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "30px",
              marginTop: "30px",
            }}
          >
            <th scope="Grid">
              <img
                src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/LogoLightMode.svg"
                height="100"
                alt=""
                style={{ verticalAlign: "middle" }}
              />
            </th>

            <th scope="Grid" style={{ paddingLeft: "20px" }}>
              <table width="450">
                <tbody>
                  <tr>
                    <th scope="Grid">
                      <p
                        style={{
                          color: "#0066ff",
                          fontSize: "20px",
                          fontWeight: "bold",
                          marginTop: "0px",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        CONEXIÓN CON TU BIENESTAR
                      </p>
                      <hr
                        style={{
                          border: "none",
                          height: "2px",
                          backgroundColor: "#0066ff",
                          margin: "0 auto",
                          marginTop: "10px",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      />
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <table width="450">
                        <tbody>
                          <tr>
                            <th id="fino" scope="Grid" align="left">
                              <img
                                src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/phoneLightMode.svg"
                                height="20"
                                alt=""
                                style={{ verticalAlign: "middle" }}
                              />
                              <span style={{ verticalAlign: "middle" }}>
                                0800 888 3554
                              </span>
                            </th>
                            <th id="fino" scope="Grid" align="left">
                              <a
                                href="https://ellie.care"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  textDecoration: "none",
                                  color: "#3c3c3b",
                                }}
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/worldLightMode.svg"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                                <span style={{ verticalAlign: "middle" }}>
                                  www.ellie.care
                                </span>
                              </a>
                            </th>
                            <th scope="Grid">
                              <a
                                href="https://www.linkedin.com/company/ellie-care/mycompany/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/linkedinLightMode.svg"
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                              </a>
                            </th>
                            <th scope="Grid">
                              <a
                                href="https://www.instagram.com/elliecare.latam/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/instagramLightMode.svg"
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                              </a>
                            </th>
                            <th scope="Grid">
                              <a
                                href="https://twitter.com/elliecareok"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/twitterLightMode.svg"
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                              </a>
                            </th>
                            <th scope="Grid">
                              <a
                                href="https://www.facebook.com/Ellie-Care-114087367948463"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src="https://elliecare-public-images.s3.sa-east-1.amazonaws.com/mailing-images/svgs/facebookLightMode.svg"
                                  width="20"
                                  height="20"
                                  alt=""
                                  style={{ verticalAlign: "middle" }}
                                />
                              </a>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </th>
          </tr>
        </tbody>
      </table>
    );
  }

  // //-- Formulario de Envío ---------------------------//

  const [formData, setFormData] = useState<FormData>({
    first_name: "",
    last_name: "",
    birth_date: "",
    gender: "",
    billing_first_name: "",
    billing_last_name: "",
    billing_document_number: "",
    billing_phone_number: { country_code: "", number: "" },
    billing_email: "",
    billing_street: "",
    billing_number: "",
    billing_zipcode: "",
    billing_floor: "",
    billing_apartment: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_extra_info: "",
  });

  const handleInputChangeData = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    isAEmail(event.target.value);
    console.log(formData.birth_date);
    
  };

  function isAEmail(value: string) {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regexEmail.test(value)) {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }
  }

  function handleNewAdress(address: string | null) {
    if (address === null) {
      setFormData((prevState) => ({
        ...prevState,
        billing_street: formData.billing_street,
        billing_city: formData.billing_city,
        billing_state: formData.billing_state,
        billing_country: formData.billing_country,
      }));
    } else {
      const arrayWord = address.split(",");
      // console.log(arrayWord);
      setFormData((prevState) => ({
        ...prevState,
        billing_street: arrayWord[0],
        billing_city: arrayWord[1],
        billing_state: arrayWord.length === 3 ? arrayWord[1] : arrayWord[2],
        billing_country: arrayWord.length === 3 ? arrayWord[2] : arrayWord[3],
      }));
    }
  }

  const handleSelectChangeData = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [open, setOpen] = useState(false); // Aquí agregamos el estado para el DatePicker

  const handleDateChange = (date: moment.Moment | null, context: PickerChangeHandlerContext <DateValidationError>) => {
    const dateMoment = date ? date.unix().valueOf().toString() : "";
    setFormData({
      ...formData,
      birth_date: `${dateMoment}000`,
    });
    handleError(context.validationError);
  };

  const handleError = (error: DateValidationError) => {
    if(error){
      setfnInvalid(true);
    }else {
      setfnInvalid(false);
    }
  }

  // Lógica para verificar si todos los campos obligatorios están completos
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Array para almacenar los nombres de los campos obligatorios incompletos
    const camposIncompletos: string[] = [];

    // Verificar si los campos obligatorios están completos
    if (!formData.first_name) camposIncompletos.push("Nombre del Adulto Mayor");
    if (!formData.last_name)
      camposIncompletos.push("Apellido del Adulto Mayor");
    if (formData.gender === "") camposIncompletos.push("Género");
    if (formData.birth_date !== "" && fnInvalid)
      camposIncompletos.push("Fecha de nacimiento no válida.");
    if (formData.birth_date === "")
      camposIncompletos.push("Fecha de nacimiento del adulto mayor.");
    if (!formData.billing_first_name) camposIncompletos.push("Nombre de envío");
    if (!formData.billing_last_name)
      camposIncompletos.push("Apellido de envío");
    if (!formData.billing_document_number) camposIncompletos.push("Documento de envío");
    if (!formData.billing_phone_number)
      camposIncompletos.push("Teléfono de envío");
    if (!formData.billing_email)
      camposIncompletos.push("Correo electrónico de envío");
    if (emailInvalid) camposIncompletos.push("Email invalido");
    if (!formData.billing_street) camposIncompletos.push("Calle de envío");
    if (!formData.billing_zipcode)
      camposIncompletos.push("Código postal de envío");
    if (!formData.billing_city) camposIncompletos.push("Ciudad de envío");
    if (!formData.billing_state) camposIncompletos.push("Provincia de envío");
    if (!formData.billing_country) camposIncompletos.push("País de envío");
    if (!checkedTerms)
      camposIncompletos.push("Aceptar los Términos y Condiciones.");

    // Si hay campos incompletos, mostrar un mensaje de alerta con esos campos
    if (camposIncompletos.length > 0) {
      const mensajeAlerta = `Por favor, completá los siguientes campos obligatorios:\n\n ⊗  ${camposIncompletos.join(
        "\n ⊗  "
      )}`;
      alert(mensajeAlerta);
    } else {
      // window.location.href = watch_url;
      setIsProcesingPayment(true);
      createPreCheckoutMP(formData, uid)
        .then((res) => {
          if (res) {
            window.location.href = res.url;
          } else {
            navigate(`/error`);
          }
        })
        .catch((error) => {
          console.error(error);
          navigate(`/error`);
        });
    }
  };

  // Función para mostrar el Formulario
  function renderForm() {
    return (
      <form>
        <Grid container>
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid item xs={12}>
              <p className="subtitle-m mar-t-10 mar-b-10">
                ¿Quién va a <b>usar</b> el reloj?
              </p>
            </Grid>
            <Grid item xs={5.8}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                label="Nombre del Adulto Mayor"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChangeData}
                required
              />
            </Grid>
            <Grid item xs={5.8}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                label="Apellido del Adulto Mayor"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChangeData}
                required
              />
            </Grid>
            <Grid item xs={5.8} marginTop={"20px"} zIndex={"20"}>
              <DatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                label="Fecha de nacimiento del Adulto Mayor"
                onChange={handleDateChange}
                onError={handleError}
                format="DD/MM/YYYY"
                sx={{ width: "100%" }}
                disableFuture
                slotProps={{
                  textField: {
                    required: true
                  },
                }}
              />
            </Grid>
            <Grid item xs={5.8} marginTop={"20px"}>
              <FormControl fullWidth>
                <InputLabel id="gender-label">
                  Género del Adulto Mayor ﹡
                </InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  name="gender"
                  defaultValue="Seleccione..."
                  onChange={handleSelectChangeData}
                  label="Género del Adulto Mayor"
                  style={{ width: "100%" }}
                  required
                >
                  <MenuItem value="Seleccione..." disabled>
                    Seleccione...
                  </MenuItem>
                  <MenuItem value="male">Masculino</MenuItem>
                  <MenuItem value="female">Femenino</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid item xs={12}>
              <p className="subtitle-m mar-t-10 mar-b-10">
                ¿A quién <b>enviamos</b> el reloj?
              </p>
            </Grid>
            <Grid item xs={5.8}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                label="Nombre"
                name="billing_first_name"
                value={formData.billing_first_name}
                onChange={handleInputChangeData}
                required
              />
            </Grid>
            <Grid item xs={5.8}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                label="Apellido"
                name="billing_last_name"
                value={formData.billing_last_name}
                onChange={handleInputChangeData}
                required
              />
            </Grid>
            <Grid item xs={3.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                label="Número de Documento"
                name="billing_document_number"
                value={formData.billing_document_number}
                onChange={handleInputChangeData}
                required
              />
            </Grid>
            <Grid item xs={3.8} marginTop={"20px"} style={{ zIndex: "20" }}>
              <PhoneInput
                autocompleteSearch={true}
                country={"ar"}
                onlyCountries={["ar", "uy", "mx", "co"]}
                // preferredCountries={['ar','uy','mx','co']}
                placeholder="Número de teléfono celular / WhatsApp ﹡"
                inputStyle={{
                  height: "56px",
                  width: "100%",
                  fontSize: "1rem",
                  paddingLeft: "70px",
                }}
                buttonStyle={{
                  width: "56px",
                  paddingLeft: "3px",
                  backgroundColor: "transparent",
                }}
                localization={es}
                // value={formData.shipping_phone_number}
                onChange={(value, country, e, formattedValue) => {
                  // console.log("Value:" + value);
                  // console.log(country);
                  if (country && "dialCode" in country) {
                    setFormData({
                      ...formData,
                      billing_phone_number: {
                        country_code: country.dialCode,
                        number: value.substring(country.dialCode.length),
                      },
                    });
                    // console.log(
                    //   "dialCode:" + formData.billing_phone_number.country_code
                    // );
                    // console.log(
                    //   "number:" + formData.billing_phone_number.number
                    // );
                    // console.log("---------------------------------:");
                  }
                }}
                inputProps={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={3.8} marginTop={"20px"}>
              <TextField
                type="email"
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                label="Dirección de email"
                name="billing_email"
                value={formData.billing_email}
                onChange={handleChangeEmail}
                required
              />
            </Grid>
          </Grid>

          <Grid
            container
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid item xs={12}>
              <p className="subtitle-m mar-t-10 mar-b-10">
                ¿A <b>dónde</b> lo enviamos?
              </p>
            </Grid>
            <Grid item xs={11.8}>
              <Autocomplete
                id="address-autocomplete"
                value={value}
                onChange={(event, newValue) => {
                  handleNewAdress(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setValue(newInputValue);
                }}
                defaultValue={
                  value !== ""
                    ? value
                    : `${formData.billing_street}, ${formData.billing_city}, ${formData.billing_state}, ${formData.billing_country}`
                }
                options={data.map(({ description }) => description)}
                autoComplete
                freeSolo
                disabled={!ready}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Calle / Número / Ciudad / Provincia / País"
                    onChange={(e) => {
                      setValue(e.target.value);
                      if (e.target.value === "") {
                        clearSuggestions();
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={5.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                label="Calle"
                name="billing_street"
                value={formData.billing_street}
                onChange={handleInputChangeData}
                required
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>
            <Grid item xs={5.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*", // Esto es opcional, para ayudar a los navegadores a mostrar el teclado numérico
                }}
                label="Número"
                name="billing_number"
                value={formData.billing_number}
                onChange={handleInputChangeData}
              />
            </Grid>
            <Grid item xs={3.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                type="number"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*", // Esto es opcional, para ayudar a los navegadores a mostrar el teclado numérico
                }}
                name="billing_floor"
                label="Piso"
                value={formData.billing_floor}
                onChange={handleInputChangeData}
              />
            </Grid>
            <Grid item xs={3.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                name="billing_apartment"
                label="Departamento"
                value={formData.billing_apartment}
                onChange={handleInputChangeData}
              />
            </Grid>
            <Grid item xs={3.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                name="billing_zipcode"
                label="Código Postal (Ej: 72690)"
                value={formData.billing_zipcode}
                onChange={handleInputChangeData}
                required
              />
            </Grid>
            <Grid item xs={3.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                name="billing_state"
                label="Provincia (Ej: Buenos Aires)"
                value={formData.billing_state}
                onChange={handleInputChangeData}
                required
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>
            <Grid item xs={3.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                name="billing_city"
                label="Localidad (Ej: Saladillo)"
                value={formData.billing_city}
                onChange={handleInputChangeData}
                required
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>
            <Grid item xs={3.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                name="billing_country"
                label="País (Ej: Argentina)"
                value={formData.billing_country}
                onChange={handleInputChangeData}
                required
                disabled
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
              />
            </Grid>
            <Grid item xs={11.8} marginTop={"20px"}>
              <TextField
                style={{ backgroundColor: "#FFFFFF" }}
                fullWidth
                label="Información adicional para el envío"
                name="billing_extra_info"
                value={formData.billing_extra_info}
                onChange={handleInputChangeData}
              />
            </Grid>
          </Grid>
        </Grid>

        <hr />
      </form>
    );
  }
  //--------------------------------------------------//

  return (
    <LocalizationProvider adapterLocale={"es"} dateAdapter={AdapterMoment}>
      <>
        <Container
          maxWidth="lg"
          sx={{
            minWidth: "700px",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {isProcesingPayment ? (
            <Grid
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              m={6}
            >
              <Loader title="Estamos procesando tu pedido..." size={120} />
              <Typography mt={5}>
                En un momento te redirigiremos para que puedas completar tu
                pago.
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid
                item
                md={10}
                className="bg-ui-01 card-success p-4"
                sx={{
                  minHeight: "100vh",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      backgroundColor: "#0066ff",
                      color: "#f5f7f9",
                      textAlign: "center",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <h2>
                      Suscripción Gold {isLoan ? "Comodato" : ""} de Ellie Care
                    </h2>
                  </Grid>

                  <h1 className="title mar-t-32">{"Información importante"}</h1>
                  <p className="mar-t-10 mar-b-10">
                    Para comenzar, te vamos a pedir que nos ayudes completando
                    algunos datos para poder realizar la suscripción a tu Plan
                    Gold y podamos enviarte el SmartWatch Samsung de Ellie Care.
                  </p>

                  {renderForm()}

                  <p className="mar-t-10 mar-b-32">
                    Nos vamos a poner en contacto con vos para realizar el envío
                    del reloj cuándo lo tengamos preparado.
                  </p>

                  {/* Terminos y condiciones */}
                  <Grid display="flex" gap={1} alignItems="center" my={2}>
                    <input
                      type="checkbox"
                      id="scales"
                      name="scales"
                      checked={checkedTerms}
                      onChange={handleChange}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <Typography>
                      Acepto{" "}
                      <a
                        href={URLTermsAndCondition}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Términos y Condiciones
                      </a>{" "}
                      y{" "}
                      <a
                        href={URLPoliciesPrivacy}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Políticas de privacidad.
                      </a>
                    </Typography>
                  </Grid>

                  {/* ---- INICIO DEL BOTÓN DE SUSCRIPCIÓN ----*/}
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Grid item xs={11.8} marginTop={"20px"}>
                      <Button
                        fullWidth
                        style={{
                          backgroundColor: "#0066FF",
                          color: "#FCFCFC",
                          height: "56px",
                        }}
                        type="submit"
                        className="w-100"
                        onClick={(e) => handleSubmit(e)}
                      >
                        {mepaLogo}{" "}
                        <p style={{ marginLeft: "20px" }}>
                          Continuar con la suscrición a tu Red de Ayuda tipo
                          Gold
                          {isLoan ? " Comodato" : ""}
                        </p>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </>
          )}

          {renderFooter()}
        </Container>
      </>
    </LocalizationProvider>
  );
};
