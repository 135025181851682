// TODO: check memory usage at deploy time
import './../styles/components/_icon.scss'
import * as iconsSolid from '@fortawesome/free-solid-svg-icons'
import * as iconsRegular from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'

const iconSolidMap = new Map(Object.entries(iconsSolid))
const iconRegularMap = new Map(Object.entries(iconsRegular))

export type IconType = keyof typeof iconsSolid

interface IconProps {
    icon: IconType
    size?: 'normal' | 'big'
    regular?: boolean
    className?: string
}

export const Icon = ({ icon, size = 'normal', regular = false, className}: IconProps) => {
    return (
        <FontAwesomeIcon
            className={classNames(`icon__${size}`, className)}
            icon={regular
                ? iconRegularMap.get(icon) as IconProp
                : iconSolidMap.get(icon) as IconProp}
        />
    )
}