import axios from "axios";

const baseUrl = process.env.REACT_APP_API_HOST;
axios.defaults.baseURL = baseUrl;

export async function getAllPLans(){
    const resp = await axios.get(`app/v3/bo/plans`);
    return resp.data;
}

export async function existOnePlan(id:string) {
    const resp = await axios.get(`app/v3/plans/exist/${id}`)
    return resp.data;
}
