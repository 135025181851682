import { createElement } from "react"
import classNames from 'classnames';

type TitleComponentType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

interface TitleProps {
    component: TitleComponentType
    className?: string
    text: string
    color?: string
}

export const Title = ({ component, className, text, color = 'text-01' }: TitleProps) => {
    return (
        createElement(component,
            { className: classNames(`m-0 color-${color}`, className) },
            [text])
    )
}