import { Avatar } from "./Avatar"
import { Title } from "./Title"
import '../styles/components/_loading.scss'

interface LoaderProps {
    title: string
    color?: 'blue' | 'white'
    size?: 48 | 72 | 120
}

export const Loader = ({ color = 'blue', size = 48, title }: LoaderProps) => {
    return (
        <div className="d-flex center-box flex-column loader__container pt-4">
            <Avatar variation={2} size={size} className='w-min-content loading-screen__avatar' />
            <Title component="h3" text={title} className="m-0 py-2 color-text-05" />
        </div>
    )
}