import 'bootstrap/dist/css/bootstrap.min.css'
import { AppRouter } from './router/AppRouter'
import './styles/styles.scss'


export const CheckoutApp = () => {
    return (
        <AppRouter />
    )
}

