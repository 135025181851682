import { useEffect, useState } from "react";
import { Loader } from "../Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { existOnePlan } from "../../services/plans.service";
import { Formulario } from "../statusTypes/Formulario";

export const CheckoutHost = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid") || ""; //plan_id
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [existPlan, setExistPlan] = useState(false);
  const [infoPlan, setInfoPlan] = useState({
    isLoan: false,
    plan_type: 'gold',
  })

  useEffect(() => {
    setLoading(true);
    if (uid !== "") {
      existOnePlan(uid)
        .then((res) => {
          setInfoPlan({isLoan: res.isLoan, plan_type: res.plan_type});
          setExistPlan(true);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setExistPlan(false);
          navigate(`/error`);
        });
    } else {
      navigate(`/error`);
    }
  }, [navigate, uid]);

  if (loading) return <Loader title="Cargando..." size={120} />;
  return <div>{existPlan && <Formulario isLoan={infoPlan.isLoan} plan_type={infoPlan.plan_type}/>}</div>;
};
