import '../../styles/components/_success.scss';
import backGreen from '../../images/background/background-green.svg';
import backGreenMobile from '../../images/background/background-green-mobile.svg';
import downloadImg from '../../images/background/success-sub.svg';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { Col, Container, Row } from 'react-bootstrap';
import { ReactComponent as Playstore } from '../../images/icons/google-play.svg';
import { ReactComponent as AppleStore } from '../../images/icons/apple-store.svg';
import { ReactComponent as QRPlaystore } from '../../images/icons/QRPlayStore.svg';
import { ReactComponent as QRAppleStore } from '../../images/icons/QRAppStore.svg';
import React, { useEffect } from 'react';

export const Download = () => {
  
  const mobileQuery = useMediaQuery('(max-width: 768px)');
  const imageSrc = mobileQuery ? backGreenMobile : backGreen;
  const imageAlt = '';
  const playStoreLink = 'https://play.google.com/store/apps/details?id=com.elliego';
  const appleStoreLink = 'https://apps.apple.com/ar/app/ellie-care-go/id6446497748';
  
  useEffect(() => {
    // Detectar el sistema operativo del usuario
    const userAgent = window.navigator.userAgent;

    if (/android/i.test(userAgent.toLowerCase())) {
      // Es un dispositivo Android
      window.location.href = playStoreLink;
    } else if (/iphone|ipad|ipod/i.test(userAgent.toLowerCase())) {
      // Es un dispositivo iOS
      window.location.href = appleStoreLink;
    } else {
      // Otro dispositivo o navegador
      // Puedes agregar aquí la lógica adicional si es necesario
      console.log('Otros dispositivos o navegadores');
    }
  }, []);

  return (
    <div className="success-container bg-ui-background">
      <div className="img-container">
        <img src={imageSrc} alt={imageAlt} className="w-100 img-green" />
      </div>
      <Container className="z-index-100 card-position">
        <Row className="d-flex center-box flex-direction-column p-3">
          <Col md={2} className="d-flex justify-content-center">
            <img src={ downloadImg } alt="" style={{ maxHeight: '200px' }}/>
          </Col>
          <Col md={8} className="bg-ui-01 card-success p-4">
            <h1 className="title">{'¡Bienvenido a Ellie Care!'}</h1>
           
                <p className="subtitle-m mar-t-10 mar-b-20">
                  Descarga la App <b>Ellie Care GO</b> en tu celular escaneando el código QR o haciendo click en el botón correspondiente a tu sistema operativo.
                </p>
                <Row>
                  <Col xs={6}>
                    <a href={playStoreLink}>
                      <Playstore style={{ width: '100%' }} />
                    </a>
                  </Col>
                  <Col xs={6}>
                    <a href={appleStoreLink}>
                      <AppleStore style={{ width: '100%' }} />
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <a href={playStoreLink}>
                      <QRPlaystore style={{ width: '100%', height: '160px' }} />
                    </a>
                  </Col>
                  <Col xs={6}>
                    <a href={appleStoreLink}>
                      <QRAppleStore style={{ width: '100%', height: '160px' }} />
                    </a>
                  </Col>
                </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};