interface AvatarProps {
    color?: 'blue' | 'white'
    size?: 48 | 72 | 120
    variation?: 1 | 2 | 3
    className?: string
}

export const Avatar = (
    {
        color = 'blue',
        size = 48,
        variation = 1,
        className
    }: AvatarProps) => {

    return (
        <img
            src={require(`./../images/avatar/variation=${variation}-size=${size}-color=${color}.svg`)}
            alt="avatar"
            className={className}
        />
    )
}