import axios from "axios";

const baseUrl = process.env.REACT_APP_API_HOST;
axios.defaults.baseURL = baseUrl;

export async function createPreCheckoutMP(body: any, plan: string){
    const _body = {
        ...body,
        plan
    }
    const resp = await axios.post(`app/v3/mercadopago/pre-checkout`, _body);
    return resp.data;
}