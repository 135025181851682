import '../styles/components/_button.scss'
import classNames from 'classnames'
import { Icon, IconType } from './Icon'

interface ButtonProps {
    text?: string
    onClick?: () => void
    variation?: 'primary' | 'secondary' | 'tertiary'
    size?: 'm' | 'l'
    icon?: IconType
    iconRight?: boolean
    disabled?: boolean
    type?: 'submit' | 'reset' | 'button'
    className?: string
}

export const Button = (
    {
        text,
        variation = 'primary',
        size = 'm',
        icon,
        iconRight = false,
        className,
        ...props
    }: ButtonProps) => {

    return (
        <button
            {...props}
            className={classNames(
                'button d-flex center-box gap-8',
                `button__${variation} button-${size} button-${size}-sb`,
                { 'flex-row-reverse': iconRight },
                { 'button__square': !text },
                className
            )}
        >
            {icon && <Icon
                icon={icon}
                size={text || size === 'm' ? 'normal' : 'big'}
            />}
            {text}
        </button>
    )
}